
import { Component, Vue } from 'vue-property-decorator'
import { DetailInfo } from '@/types/scenarios'

@Component
export default class ScenariosDetail extends Vue {
  private info: DetailInfo = {
    /** 预案名称 */
    scenariosName: '',
    /** 事件类型id */
    typeId: '',
    /** 预案目的 */
    scenariosPurpose: '',
    /** 职责分工 */
    responsibilities: '',
    /** 注意事项 */
    attention: '',
    /** 预案演练 */
    drill: '',
    /** 预案内容 */
    scenariosContent: '',
    /** 小组人员列表 */
    memberList: []
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get<DetailInfo>(this.$apis.scenarios.selectDispatchScenariosByScenariosId, {
      scenariosId: this.$route.params.scenariosId
    }).then(res => {
      this.info = res || {}
    })
  }
}
